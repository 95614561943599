/*
 * Adaptive images
 */
;(function ($, window, document, undefined) {
	'use strict';

	var pluginName = 'adaptiveImages',
		defaults = {
			// Moet er rekening gehouden worden met retina schermen, schermen
			// met een resolutie van 1024*800 met retina scherm krijgen dan
			// foto's van 2048*1600
			retina: true,
			// Is er een maximale waarde voor de dpr, een nieuwe high end smartphone
			// heeft namelijk vaak een dpr van 3 of 4, dus afbeeldingen van 500 worden
			// dan alsnog 2000.
			maxDpr: 1.5,
			// Hoeveel procent van het scherm gebruiken we voor de maximale schermgroote
			// als je de helft van het scherm wilt gebruiken dan geef je hier 0.5 op
			screenPercent: 1,
			// Hierin kun je een function meegeven waarin je de value van de afbeelding
			// kunt zetten, dit kun je gebruiken als je standaard niet de src van
			// de afbeelding wilt aanpassen maar bijvoorbeeld de href van de parent
			setSrc: function(imageSrc) {
				$(this).attr('src', imageSrc);
			}
		};

	// Plugin constructor
	function Plugin(element, options) {
		// Losse element dat aangeroepen word
		this.element = element;
		// Sla de opties op en overschrijf de defaults met de opgegeven opties
		this.settings = $.extend({}, defaults, options);

		this._defaults = defaults;
		this._name = pluginName;

		// Begin de plugin
		this.init();
	}

	$.extend(Plugin.prototype, {
		// Initialiseren van de plugin
		init: function () {
			var that		= this,
				maxWidth	= that.getMaxWidth(that),
				paths		= [],
				widthFound	= false,
				lastPath;

			// Haal de paden op
			$.each(that.element.attributes, function() {
				// Kijk of dit attribuut wel op het element staat
				if (this.specified) {
					// Is het een breedte?
					if (this.name.indexOf('data-size-') === 0) {
						// Voeg toe aan de array
						paths.push({
							src: this.name.substring(('data-size-').length),
							width: this.value
						});
					}

				}
			});

			// Zorg dat de hoogste waardes eerst komen
			paths.sort(function(a, b) {
				return b.width - a.width;
			});

			// Loop door de verschillende maten heen en bepaal de beste afbeelding
			$.each(paths, function(index, value) {
				var
				// Width van de afbeelding
				width = parseInt(String(value.width).replace(/[^0-9.]/g, '')),

				// Pad van de afbeelding
				path = value.src;

				// Als de width minder is dan de maximale breedte van het scherm
				// dan pakken we de laatste afbeeldingmaat die we opgeslagen hebben
				// Dit is namelijk de kleinst mogelijke afbeelding
				if (width <= maxWidth) {
					// Zet de source op de afbeelding
					that.setSrc(that, lastPath, path);

					// Afbeelding is gevonden
					widthFound = true;

					// We hebben de juiste maat al, de rest hoeven we niet doorheen
					return false;
				}

				lastPath = path;
			});

			// Kijk of we wel een path gevonden hebben
			// Zo niet dan pakken we de kleinst mogelijke afbeeling maat
			if (!widthFound) {
				that.setSrc(that, lastPath);
			}
		},
		// Pak de maximale breedte van het scherm
		getMaxWidth: function(that) {
			var
			// Bepaal de maximale breedte van het scherm
			width = Math.max(window.innerWidth || document.body.clientWidth, window.innerHeight || document.body.clientHeight) * that.settings.screenPercent,

			// Device pixel ratio voor bijv. retina schermen, als fallback pakken we 1
			dpr = window.devicePixelRatio || (window.screen.deviceXDPI / window.screen.logicalXDPI) || 1;

			// Houden we rekening met retina schermen?
			if (that.settings.retina) {
				// Bekijk of de dpr moet aangepast worden obv van de settings
				dpr = Math.min(that.settings.maxDpr, dpr);

				return width * dpr;
			}
			// We houden geen rekening met retina schermen..
			else {
				return width;
			}
		},
		// Zet de source op de afbeelding
		setSrc: function(that, lastPath, path) {
			// Is er een functie opgegeven om de src te zetten, zoja roep
			// die dan aan
			if (typeof that.settings.setSrc === 'function') {
				that.settings.setSrc.apply(that.element, [lastPath ? lastPath : path]);
			}
			// Standaard zetten we het attribuut src van het element naar
			// de afbeelding src
			else {
				$(that.element).attr('src', lastPath ? lastPath : path);
			}
		}
	});

	$.fn[pluginName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'plugin_' + pluginName)) {
				$.data(this, 'plugin_' + pluginName, new Plugin(this, options));
			}
		});
	};

})(jQuery, window, document);
