// jquery.accordion.js - 20130522 @ 07:37:47
/**
 * v1.1:
 * - unfoldIfNoHash optie toegevoegd;
 *
 * Een simpele accordion met # navigatie ( dus ook browser back werkt )
 * LET OP: Maakt gebruik van de onhashchanged plugin
 *
 * accordion geeft het beste resultaat in window.load omdat dan de berekening
 * van de bovenkant van het item klopt als er bijvoorbeeld bovenin nog afbeeldingen
 * staan die "langzamer" laden:
 $(window).load(function(){
    $('.accordion').accordion(opties);
  });
 */
;(function($) {
  $.fn['accordion'] = function(options) {
    options = $.extend({}, {
      unfoldIfNoHash: true,   // Moet het eerste item automatisch uitgevouwen worden als er geen hash is?
      scrollIfNoHash: true,   // Als het eerste item automatisch uitgevouwen wordt, moeten we daar ook naartoe scrollen?
      blink: false,   // moet het item knipperen als we er naar toe zijn gescrolled? ( LET OP: hier is $.animateLoop() voor nodig )
      anchor: 'rel',    // het attribuut in de trigger welk de inhoud van de hash bepaald
      triggers: 'span'    // de selector welke binnen de "hoofd"-selector de triggers bepaald
    }, options);

    $(this).each(function() {
      var $this = $(this),
        $triggers = $this.find(options.triggers);

      // loop door alle triggers heen
      $triggers.each(function() {
        var $trigger = $(this);

        $trigger.next().hide();

        $trigger.bind('show', function() {
          $trigger.next().slideDown(function() {
            $trigger.addClass('open');
            $trigger.trigger('afterShow');
          });
        });
        $trigger.bind('hide', function() {
          $trigger.next().slideUp(function() {
            $trigger.removeClass('open');
            $trigger.trigger('afterHide');
          });
        });

        // ipv meteen animeren veranderen we hier alleen de hash
        $trigger.click(function(e) {
          if (!$trigger.is('.open')) {
            // kijk of we al op deze hash zitten
            if ($trigger.attr(options.anchor) == $.getHashvalue()) {
              // de hash is onveranderd, we moeten dus "handmatig" tonen
              $trigger.trigger('show');
            }

            // stel de hash in
            location.href =
              location.href.split('#')[0] +   // verwijder de oude hash
              '#' + $trigger.attr(options.anchor);  // voeg de nieuwe toe
          } else {
            // verbergen ipv tonen, da hash maakt nu niet uit
            $trigger.trigger('hide');
          }
        });
      });

      // als de hash veranderd gaan we kijken of we een uitem kunnen gaan uitvouwen
      var firstHash = $.getHashvalue(),
        first = true; // alleen bij het openen van de pagina gaan we scrollen naar het item
      $(document).hashchanged(function(e) {
        // zoek het item op
        var hash = $.getHashvalue(),
          $trigger = $triggers.filter('[' + options.anchor + '="' + hash + '"]');

        // alle items verbergen
        $triggers.not($trigger).trigger('hide');

        // kijuk of er een item is gevonden
        if ($trigger.size() > 0) {

          if ($trigger.is('.open'))  $trigger.trigger('hide');
          else            $trigger.trigger('show');

          // zorg, alleen de eerste keer bij openen pagina,
          // dat we ook naar het item scrollen zodra deze klaar is met opnenen
          if (first) {
            $trigger.bind('afterShow', function() {
              // scroll naar het nu uitgevouwen item
              if (firstHash || options.scrollIfNoHash) {
                $('html, body, document').scrollTop($trigger.offset().top);
              }

              // knipper het item
              if (options.blink && typeof $.fn['animateLoop'] != 'undefined') {
                $trigger.animateLoop({
                  amount: 2,
                  beforeAnimate: function() {
                    $(this).css("opacity", 0.6);
                  }
                }, { "opacity": 1 }, 700);
              }

              // bij de volgende keer openen niet meer scrollen of animeren
              $trigger.unbind('afterShow');
            });

          }
        }

        // we zijn nu altijd voorbij het eerste item
        first = false;
      });

      // alles is er klaar voor, kijk of er al een hash is ( en of we uberhaupt een hash moeten toevoegen
      if (!firstHash && options.unfoldIfNoHash) {
        location.href =
          location.href.split('#')[0] +   // verwijder de oude hash
          '#' + $triggers.eq(0).attr(options.anchor); // voeg de nieuwe toe
      } else {
        // als options.unfoldIfNoHash false is wordt het eerste item niet uitgevouwen
        // bij de volgende hashchanged behandelen we die dus niet meer alsof we net binnenkomen op de pagina
        first = false;
      }

      // focus nu op juiste item
      setTimeout(function() {
        $(document).hashchanged()
      }, 0);
    });
  }
})(jQuery);
// Einde: jquery.accordion.js

// jquery.hashchanged.js - 20121112 @ 09:39:21
/**
 * v1.0
 *
 * Een custom event voor de hashchanged
 * De meeste brower ondersteunen al onhashchange, maar de oudere niet deze methode lost dat op
 *
 * Zo werkt het dan voor alle browsers:
 * $(element).hashchanged(function(e, hash){
 *  // de hash is altijd het 2e argument
 * });
 */
;(function($) {
  // functie om de hash waarde op te halen
  $.getHashvalue = function() {
    var hash = ( document.location.hash.toString() ).split('#')[1];

    return typeof hash != 'undefined' ? hash : '';
  }

  var event = 'hashchanged',
    delay = 200,
    hasnative = 'onhashchange' in window && ( document.documentMode === undefined || document.documentMode > 7 ),
    prevhash = $.getHashvalue(),
    built = false;

  // kijk of dit event al bestaat
  if (typeof $.fn[event] == 'function') return;

  // de jQuery stijl binding
  // als callback niet is gedefinieerd, dan doen we trigger ipv bind
  $.fn[event] = function(callback) {
    if (!$.isFunction(callback)) {
      var args = Array.prototype.slice.call(arguments);
      // voeg de hash als eerste toe aan de argumenten, dat wordt dus: (event, hash, ...);
      args.unshift($.getHashvalue());

      // doe nu de trigger
      $(this).trigger(event, args);
    } else {
      build();

      // we voegen niet meteen de callback toe, maar onze eigen functie omdat we de hash nog willen toevoegen
      $(this).bind(event, function(e) {
        // geef de hash mee aan de argumenten
        var args = Array.prototype.slice.call(arguments);

        // zet de hash op de eerste plek van de argumenten
        args[0] = $.getHashvalue();

        // schuif alle argumenten op om het event als eerste toe te voegen ( zoals JS altijd werkt )
        args.unshift(e);

        // voer de callback uit
        callback.apply(this, args);
      });
    }

    return $(this);
  }

  // De functie die bepaald hoe het event te bepalen
  function build() {
    // onthoud dat we niet meer hoeven initialiseren
    if (built) return;
    built = true;

    // we habben native hashchange ondersteuning
    if (hasnative) {
      // gebruik het native event
      window.onhashchange = function() {
        prevhash = $.getHashvalue();  // onthoud deze hash als de vorige
        $.event.trigger(event); // en roep het event aan
      };
    }
    // window.onhashchange wordt niet ondersteund
    // elke 0.2s checken of de hash is veranderd
    else {
      function poll() {
        // haal de hash op
        var hash = $.getHashvalue();

        // als er een hash is en deze match niet met de vorige
        if (hash && hash != prevhash) {
          prevhash = hash;    // onthoud deze hash als de vorige
          $.event.trigger(event); // nu de events aanroepen
        }

        timer_id = setTimeout(poll, delay); // verder met de loop
      }

      var timer_id = setTimeout(poll, delay); // voer de functie meteen uit om de loop te starten
    }
  };
})(jQuery);
// Einde: jquery.hashchanged.js

