/*
 * makeSVG
 * Zet png's om naar SVG
 --------------------------------------------------------------------*/
;(function($) {

  $.pngToSvg = function(options) {

    /*
     * ipv this die overschreven kan worden gebruiken we plugin
     */
    var _this = this;

    /*
     * Standaard opties van de plugin
     */
    var defaults = {
      directory: '/svg/',
    };

    /*
     * Functie om de src van een afbeelding te bekijken, en of deze omgezet
     * moet worden naar svg
     */
    var checkSrc = function(src) {
      return src.indexOf(_this.options.directory) !== -1;
    };

    /*
     * Functie om de te bekijken of er een .png in de rule staat en of deze
     * vervangen moet worden
     */
    var checkRule = function(rule) {
      return rule.style
        && rule.style.cssText.indexOf('.png') !== -1
        && rule.style.cssText.indexOf(_this.options.directory) !== -1;
    };

    /*
     * Functie om door een set rules heen te lopen en daarin evt. .png te
     * vervangen door .svg
     */
    var parseRules = function parseRules(rules) {
      // Loop door alle regels heen
      for (var ruleIndex in rules) {
        var rule = rules[ruleIndex];

        // Dit is een gewone regel CSS
        if (rule.type === 1) {
          if (checkRule(rule)) {
            var css = rule.style.cssText.replace('.png', '.svg');
            rule.style.cssText = css;
          }
        }

        // Dit is een responsive style
        // Bijv: @media only screen and (max-width: 1140px) {
        else if (rule.type === 4) {
          parseRules(rule.cssRules || rule.rules);
        }
      }
    };

    /*
     * init
     */
    var init = function() {
      _this.options = $.extend({}, defaults, (options || {}));

      // Loop alle afbeeldingen na
      $('img[src$=".png"]').each(function() {
        if (checkSrc(this.src)) {
          this.src = this.src.replace('.png', '.svg');
        }
      });

      // Loop door de CSS heen
      for (var sheetIndex in document.styleSheets) {
        var sheet = document.styleSheets[sheetIndex];

        // Als we hier geen toegang toe hebben krijgen we een error
        // Bijvoorbeeld bij de google fonts (cross-domain)
        try {
          var rules = sheet.cssRules || sheet.rules;
        } catch (err) {
          var rules = false;
        }

        if (rules) {
          parseRules(rules);
        }
      }
    };

    init();
  };

})(jQuery);
