/* equalHeight plugin
 --------------------------------------------------------------------*/
;(function($) {

  var Eheight = function(that, options) {

    /*
     * Standaard opties van de plugin
     */
    var defaults = {
      element: $(that),
      minHeight: 0,
      per: 0,
    };

    /*
     * Zorg dat de options als deze niet meegegeven zijn niet undefined zijn
     */
    options = options ? options : {};

    /*
     * ipv this die overschreven kan worden gebruiken we plugin
     */
    var _this = this;

    /*
     * Functie die alles begint
     */
    var init = function() {
      _this.options = $.extend({}, defaults, options);

      $(window).bind('load resize', function() {
        setHeight();
      }).trigger('resize');
    };

    /*
     * Functie om de hoogte te bepalen
     */
    var setHeight = function() {
      _this.options.element.css('height', 'auto');
      if (typeof _this.options.per === 'function' || _this.options.per > 0) {
        setHeightPer();
      } else {
        setHeightAll();
      }
    };

    /*
     * Functie om de hoogte te bepalen, op basis van de hoogste van allemaal
     */
    var setHeightAll = function() {
      var maxHeight = 0;

      _this.options.element.each(function() {
        if ($(this).is(':visible'))
          maxHeight = Math.max(_this.options.minHeight, getHeight($(this)), maxHeight);
      }).css('height', maxHeight);
    };

    /*
     * Functie om de hoogte te bepalen, op basis van X per keer
     */
    var setHeightPer = function() {
      var maxHeight = 0;
      var q = 0;
      var per = typeof _this.options.per === 'function' ? _this.options.per(window.innerWidth || document.body.clientWidth) : _this.options.per;

      for (; q < _this.options.element.length; q += per) {
        maxHeight = 0;
        getRange(q, (q + per - 1)).each(function() {
          maxHeight = Math.max(_this.options.minHeight, getHeight($(this)), maxHeight);
        }).css('height', maxHeight);
      }
    };

    /*
     * Functie om X aantal elementen te pakken
     */
    var getRange = function(start, end) {
      var elems = [];
      for (var i = start; i <= end; i++) {
        if (_this.options.element.get(i))
          elems.push(_this.options.element.get(i));
      }

      return $(elems).map(function() {
        return $(this).get();
      });
    };

    /*
     * Functie om de hoogte te krijgen van een element
     * er word rekening gehouden met boxsizing
     */
    var getHeight = function($element) {
      return parseFloat($element.css('height'));
    };

    /*
     * Functie om de 'per hoeveel' aan te passen
     */
    _this.setPer = function(x) {
      _this.options.per = x;
      setHeight();
    };

    init();

  };

  $.fn.equalHeight = function(options) {
    var _this = this;
    $(function() {
      new Eheight(_this, options);
    });
  };

})(jQuery);
